// tslint:disable-next-line:no-submodule-imports
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {NgModule} from "@angular/core";
// tslint:disable-next-line:no-submodule-imports
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {
  EXCLUDE_AUTH_TOKEN,
  HttpAuthenticationService,
  RequestPatternModel
} from "@synisys/idm-authentication-client-js";

import {CurrentLanguageProvider, SessionDataProvider} from "@synisys/idm-session-data-provider-api-js";
import {
  CurrentLanguageManager,
  LoginGuard,
  LoginPagePreventionGuard,
  MainOnlineProvidersModule,
  SessionDataProviderImpl,
  UserSessionIdentityIntercepter
} from "@synisys/idm-ui-shell";
import {HttpUserService, UserService} from "@synisys/idm-um-client-js";
import {AsyncLocalStorageModule} from "angular-async-local-storage";
import {FlashMessagesService} from "angular2-flash-messages";
import {AuthTokenInterceptor, UnauthorizedInterceptor} from "ngx-cookie-service";

import {AppComponent} from "./app.component";
import {appRoute} from "./app.routing";
import {HouseholdControlsModule} from "./components/household/household-controls.module";

/*import {
  ApplicationPropertiesService,
  DummyApplicationPropertiesService
} from "@synisys/idm-application-properties-service-client-js";
import {HttpAuthenticationService} from "@synisys/idm-authentication-client-js";*/

@NgModule({
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    appRoute,
    MainOnlineProvidersModule,
    AsyncLocalStorageModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    LoginPagePreventionGuard,
    LoginGuard,
    SessionDataProviderImpl,
    CurrentLanguageManager,
    FlashMessagesService,
    {provide: UserService, useClass: HttpUserService},
    {provide: CurrentLanguageProvider, useExisting: SessionDataProviderImpl},
    {provide: SessionDataProvider, useExisting: SessionDataProviderImpl},
    {provide: HTTP_INTERCEPTORS, useExisting: UnauthorizedInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useExisting: AuthTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useExisting: UserSessionIdentityIntercepter, multi: true},
    {provide: EXCLUDE_AUTH_TOKEN, useValue: new RequestPatternModel("POST", /\/authentication\/code/), multi: true},
    {
      provide: EXCLUDE_AUTH_TOKEN,
      useValue: new RequestPatternModel("POST", /\/authentication\/verifyandget/),
      multi: true
    },
    HttpAuthenticationService,
    /*{provide: ApplicationPropertiesService, useClass: DummyApplicationPropertiesService}*/
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
