import {Component, OnInit} from "@angular/core";
 import {HttpAuthenticationService} from "@synisys/idm-authentication-client-js";

@Component({
  selector: "tto-app",
  template: require('./app.component.html')
})
export class AppComponent implements OnInit {

  // constructor(private authService: HttpAuthenticationService) {
  // }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // if (window.location.origin.includes("localhost")) {
    //   this.authService.login("admin", "123456").subscribe();
    // }
  }

}
