import {ModuleWithProviders} from "@angular/core";
import {ExtraOptions, RouterModule, Routes} from "@angular/router";
import {LoginGuard, LoginPagePreventionGuard} from "@synisys/idm-ui-shell";

const appRouting: Routes = [
  {
    path: "login",
    canActivate: [LoginPagePreventionGuard],
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./login/login.module')['LoginModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  }, 'LoginModule');}) },
  },
  {
    path: "registration",
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./registration/registration.module')['AppSpecRegistrationModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  }, 'AppSpecRegistrationModule');}) },
  },
  {
    path: "resetpassword",
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('@synisys/idm-user-data-ui/app/resetPassword/reset-password.module')['ResetPasswordModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  }, 'ResetPasswordModule');}) },
  },
  {
    path: "expiredpassword",
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('@synisys/idm-user-data-ui/app/expired-password/expired-password.module')['ExpiredPasswordModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  }, 'ExpiredPasswordModule');}) },
  },
  {
    path: "",
    canLoad: [LoginGuard],
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./shell/main.shell.module')['MainShellModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  }, 'MainShellModule');}) }
  }
];

const initialRouteOptions: ExtraOptions = {
  onSameUrlNavigation: "reload"
};

export const appRoute: ModuleWithProviders = RouterModule.forRoot(appRouting, initialRouteOptions);
// {preloadingStrategy: PreloadAllModules  }
