import {enableProdMode, ViewEncapsulation} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
// tslint:disable-next-line:no-submodule-imports
import {Config} from "@synisys/idm-ui-shell/app/config";

import {AppModule} from "./app.module";

if (process.env.NODE_ENV === "production") {
  enableProdMode();
}

platformBrowserDynamic(
  [
    {provide: "imagesPath", useValue: "images"},
    {provide: "configPath", useValue: "./app/config"},
    {provide: Config.coreConfigPathToken, useValue: "./app/config"}
  ])
  .bootstrapModule(
    AppModule,
    [{defaultEncapsulation: ViewEncapsulation.None}]
  )
  .then();
